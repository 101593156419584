<template>
  <!--
    The EventDisplaySettingsDialog is a Vuetify Dialog which renders the event filters
    and event display settings. The dialog can be used in the same ways as a bare vuetify
    dialog. By default the activator is rendered as a settings icon button, but this can
    also be changed with a custom activator slot. For the slot the usage is exactly the same
    as for a bare dialog from vuetify.
  -->
  <v-dialog v-model="showDialog" width="350px">
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps">
        <v-btn v-on="activatorProps.on" icon>
          <v-badge overlap color="warning" :value="eventFilter.orgs.length">
            <span slot="badge">{{ eventFilter.orgs.length }}</span>
            <v-icon>mdi-settings</v-icon>
          </v-badge>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-app-bar dense flat>
        <v-toolbar-title>Filter &amp; Ansicht</v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <div class="text-h6 mt-2">Filter</div>
        <v-row>
          <v-col>
            <v-checkbox label="Alle" v-model="allOrgs" />
            <v-checkbox v-for="group in groups.slice(0, Math.floor(groups.length / 2))" :key="group.code" :label="group.name" :value="group.code" v-model="orgs" />
          </v-col>
          <v-col>
            <v-checkbox v-for="group in groups.slice(Math.floor(groups.length / 2))" :key="group.code" :label="group.name" :value="group.code" v-model="orgs" />
          </v-col>
        </v-row>
        <v-divider />
        <div class="text-h6 mt-3">Ansicht</div>
        <v-radio-group v-model="newDisplayStyle">
          <v-row>
            <v-col><v-radio label="Liste" value="list"></v-radio></v-col>
            <v-col><v-radio label="Tabelle" value="table"></v-radio></v-col>
          </v-row>
        </v-radio-group>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="success" text @click="save">Speichern</v-btn>
        <v-spacer />
        <v-btn text @click="showDialog = false">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import clone from 'lodash/clone'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import touringGroupService from '@/services/touringGroup'


export default {
  name: 'EventFilterCard',
  props: {},
  data() {
    return {
      showDialog: false,
      newDisplayStyle: undefined,
      orgs: [],
      groups: [],
    }
  },
  computed: {
    groupCodes: function () {
      return this.groups.map(g => g.code)
    },
    allOrgs: {
      set(val) { this.orgs = val ? this.groupCodes : val === undefined ? this.orgs : [] },
      get() { return this.orgs.length === this.groupCodes.length },
    },
    ...mapState(useUserStore, { eventFilter: store => store.settings.eventFilter }),
    ...mapState(useUserStore, { displayStyle: store => store.settings.eventDisplay }),
  },
  watch: {
    orgs(newVal) {
      if (newVal.length < this.groupCodes.length) {
        this.allOrgs = undefined
      }
    },
  },
  mounted() {
    this.fetchGroups()
    this.orgs = clone(useUserStore().settings.eventFilter.orgs)
    this.newDisplayStyle = this.displayStyle || 'list'
  },
  methods: {
    save() {
      const store = useUserStore()
      store.setEventFilter({ orgs: this.allOrgs ? [] : this.orgs })
      if (this.newDisplayStyle !== undefined && this.displayStyle !== this.newDisplayStyle) {
        store.setConfig({ key: 'eventDisplay', value: this.newDisplayStyle })
      }
      this.showDialog = false
    },
    fetchGroups() {
      return touringGroupService.getGroups().then(data => { this.groups = data; console.log(this.groups) })
    },
  },
}
</script>
